@font-face {
  font-family: "Gotham Pro";
  src: url("./GothamPro-LightItalic.woff2") format("woff2"),
    url("./GothamPro-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("./GothamPro-BlackItalic.woff2") format("woff2"),
    url("./GothamPro-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("./GothamPro-BoldItalic.woff2") format("woff2"),
    url("./GothamPro-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("./GothamPro-Bold.woff2") format("woff2"),
    url("./GothamPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("./GothamPro-Medium.woff2") format("woff2"),
    url("./GothamPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("./GothamPro-Light.woff2") format("woff2"),
    url("./GothamPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("./GothamPro-MediumItalic.woff2") format("woff2"),
    url("./GothamPro-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("./GothamPro-Black.woff2") format("woff2"),
    url("./GothamPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("./GothamPro-Italic.woff2") format("woff2"),
    url("./GothamPro-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("./GothamPro.woff2") format("woff2"),
    url("./GothamPro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
