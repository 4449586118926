.interactionParticles {
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0 auto;
  top: 0;
  left: 0;
  z-index: -10;
  &_overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0 auto;
    top: 0;
    left: 0;
    z-index: -2;
    background: rgba($dark, 0.4);
    transition: 3s opacity;
    display: none;

    @include break-lg {
      display: block;
    }
  }
  .debug {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
  }
}
