body {
  font-family: $font, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0;
  background-color: $dark;
  color: $white;
  font-size: 1rem;
  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
  // &::-webkit-scrollbar-button {
  //   background: white;
  // }
  // &::-webkit-scrollbar-track {
  //   background: $dark;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background: rgba($primary, 0.5);
  //   border: solid 2px $dark;
  //   border-radius: 100rem;
  //   &:hover {
  //     background: $teal;
  //   }
  // }
  &.blocker {
    overflow: hidden;
  }
}

::selection {
  background: $primary;
}

button,
input,
select,
textarea {
  font-family: "Gotham Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 100%;
}

* {
  box-sizing: border-box;
  &:focus {
    outline: none !important;
  }
}

.btn {
  padding: 1rem 2rem;
  background: none;
  color: $white;
  border: 1px solid white;
  border-radius: 10rem;
  cursor: pointer;
  outline: 0;
  transition: transition((background, box-shadow, color, filter));
  &.centered {
    margin: 0 auto;
    width: max-content;
    display: block;
  }
  &:active {
    transition: 0s box-shadow, 0s filter;
    box-shadow: 0 0 10px $white;
    filter: brightness(0.8);
  }
  &[disabled] {
    cursor: not-allowed;
  }
  &-loading {
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    position: relative;
    opacity: 1;
    position: relative;
    z-index: 100;
    border: 0px solid $primary;
    color: rgba(white, 0.5);
    transition: 1s color cubic-bezier(1, 0, 0, 1) !important;
    &:before {
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      content: "";
      background: $primary;
      bottom: 0;
      transform: scaleX(0.4);
      transition: 1s transform cubic-bezier(1, 0, 0, 1);
    }
    &:active {
      transition: 0s box-shadow;
      box-shadow: 0 0 0 0;
    }
    &:disabled {
      color: rgba($white, 0.2);
      background: transparent !important;
    }
    &:active {
      // box-shadow: 0 0 10px $white;
      color: white;
      &:before {
        transform: scaleX(1);
      }
    }
    &:hover {
      // background: $primary;
      color: white;
      &:before {
        transform: scaleX(1);
      }
    }
  }
  @each $name, $color in $colors {
    &-#{$name} {
      border-color: $color;
      &:hover {
        background: $color;
      }
    }
  }
}

.loading-container {
  position: relative;
  display: inline-block;
  &:not([disabled]) {
    &:before {
      clip-path: polygon(0 0, 0% 0, 0% 0%, 0 0%);
      opacity: 0;
      animation: unset;
    }
  }
  &:before {
    position: absolute;
    width: calc(100% + 5px);
    height: calc(100% + 5px);
    top: 50%;
    left: 50%;
    content: "";
    transform: translate(-50%, -50%);
    transition: transition((clip-path, transform)), opacity 1s;
    clip-path: polygon(0 0, 50% 0, 50% 50%, 0 50%);
    animation: borderAnimation 2s infinite linear;
    background: $primary;
    z-index: -2;
    border-radius: 10rem;
  }
}

.about-intro {
  display: flex;
  min-height: 100vh;
  align-items: center;
  // padding-top: 200px;
  @include break-lg {
    padding-top: 0;
    align-items: center;
  }

  h1 {
    margin-bottom: 60px;
  }
}

.about-text-intro {
  width: 600px;
  max-width: 100%;

  h6 {
    margin-bottom: 60px;
  }

  p {
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: light;
    line-height: 140%;
  }
}

p.bigText {
  margin-bottom: 40px;
  line-height: 140%;
  font-size: 24px;
}

.negativeMargin {
  margin-top: -4rem;
  @media screen and(max-width:map-get($breakpoints, "lg")) {
    margin-top: 0;
  }
}

.about-history {
  display: flex;
  .container {
    display: flex;
    flex-flow: nowrap;
  }
  &-intro {
    h6 {
      margin-bottom: 60px;
    }

    p {
      font-size: 16px;
      line-height: 27px;
      font-weight: 100;
    }
  }
  &-people {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex: 1 0 50%;
    margin-top: 30%;
    z-index: -1;
    img {
      margin-left: 2rem;
      max-width: 300px;
    }
    @media screen and(max-width:map-get($breakpoints, "lg")) {
      margin-top: 0;
      justify-content: space-between;
      img {
        margin-left: 0;
        max-width: 40%;
      }
    }
  }

  .bigText {
    margin-top: 22vh;
  }
}

button {
  cursor: pointer;
  outline: none;
  transition: transition(filter);
  &:active {
    filter: brightness(0.8);
    transition: 0s;
  }
}

img {
  width: auto;
  height: auto;
}

h2.spaced {
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.bg-centered {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 75%;
  height: 75%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  opacity: 1;
  mix-blend-mode: overlay;
}

.pos-relative {
  position: relative;
}

.single-flex-end {
  display: flex;
  align-items: flex-end;
}
a {
  color: white;
  text-decoration: none;
}
.left-bar {
  position: fixed;
  left: 3vw;
  top: 65px;
  width: 80vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translate(0, 0) rotate(90deg);
  transform-origin: left center;
  padding: 1rem 2rem;
  z-index: 20;
  font-size: 0.7rem;
  @include break-lg {
    position: absolute;
    width: 90%;
    top: 100px;
    z-index: 1000;
    transform: translate(-50%, 0);
    padding: 1.5rem 0;
    left: 50%;
    a {
      font-size: 12px;
    }
  }
  a {
    opacity: 0.5;
    font-weight: 900;
    transition: transition((opacity, color));
    text-transform: uppercase;
    &:hover {
      opacity: 1;
    }
    &.active {
      opacity: 1;
      color: $primary;
    }
  }
}

div[data-overlay] {
  position: relative;
  &:before {
    pointer-events: none;
  }
  &:before {
    content: attr(data-overlay);
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    font-family: Gotham Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18rem;
    //line-height: 335px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #ffffff;
    mix-blend-mode: overlay;
    opacity: 0.25;
    color: transparent;
    -webkit-text-stroke: 1px white;
    z-index: -1;

    @media (max-width: 425px) {
      font-size: 5rem;
      top: 4%;
    }
  }
}

.viewport {
  z-index: 1;
  margin: 0 0 0 0;
  width: 100vw;
  min-height: calc(100vh - 100px);
  overflow: hidden;
  position: fixed;
  top: 0;
  &.mobile {
    position: relative;
  }
}

.loading {
  &_logo {
    display: flex;
    align-items: center;
    position: absolute;
    top: 15vh;
    left: 0;
    img {
      max-height: 70px;
      &:first-child {
        margin-right: 1rem;
      }
      &:nth-child(2) {
        height: 20px;
      }
    }
  }
  &_container {
    z-index: 1002 !important;
    max-width: 100%;

    .wrapper {
      .whiteSpace {
        max-width: 850px;
      }
      .line {
        // white-space: pre-wrap;
      }
      .progressNum {
        position: absolute;
        bottom: 15vh;
        font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 68px;
        line-height: 39px;
        /* identical to box height, or 57% */

        letter-spacing: -0.01em;
        color: rgba(255, 255, 255, 1);
      }
      span {
        b {
          font-weight: 100;
        }
        &.accent {
          // font-weight: 400;
          font-weight: 500 !important;
          color: rgba($primary, 1);
        }

        transform: scale(1) scaleZ(1) rotateX(16deg);
        transform-origin: 50% 0%;
        perspective: 360;
      }
      .line {
        font-weight: 100;
        display: inline-block;
        color: rgba(white, 0.5);
        max-width: 900px;
        margin: 0.1rem 0;
        transform-style: preserve-3d;
        perspective: 200;
        font-family: Gotham Pro;
        font-style: normal;
        font-weight: 100;
        font-size: 26px;
        line-height: 39px;
        /* or 150% */
        letter-spacing: -0.01em;
        font-size: 26px;
        line-height: 39px;
        /* or 150% */

        letter-spacing: -0.01em;
        color: #ffffff;
        b {
          color: $primary;
          font-weight: 400 !important;
        }
        @media (max-width: 425px) {
          font-size: 18px;
          line-height: 30px;
        }

        b {
          font-weight: 100;
        }
        &.accent {
          // font-weight: 400;

          color: rgba(white, 1);
        }

        transform: scale(1) scaleZ(1) rotateX(16deg);
        transform-origin: 50% 0%;
        perspective: 360;
      }
      .line {
        margin: 2rem 0;
      }
    }
    .loading-inner {
      position: relative;
      width: 1600px;
      max-width: 90%;
      &_video {
        position: fixed;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
      svg {
        circle {
          stroke: $primary;
        }
      }
      small {
        color: rgba(white, 0.2);
        width: 100%;
        display: block;
        text-align: center;
      }
      p {
        color: $primary;
        margin: 0;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.5em;
        position: absolute;
        top: 50%;
        left: calc(50% + 0.25em);
        transform: translate(-50%, -50%);
      }

      .wrapper {
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: flex-start;
        padding-top: 15vh;

        @media (max-width: 425px) {
          padding-top: 10vh;
        }
      }
    }
  }
  &_container {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background: $dark;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    small {
      text-transform: uppercase;
    }
  }
  &_bar {
    height: 2px;
    width: 0%;
    background: $primary;
    display: flex;
    margin: 0 auto;
    margin-top: 3rem;
  }
}

.clients {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  .single_client {
    border: 1px solid rgba($white, 0.2);
    img {
      width: 50%;
      height: auto;
      object-fit: contain;
      height: 50px;
      opacity: 0.9;
      transition: transition(opacity);
      &:hover {
        opacity: 1;
      }
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    display: flex;
    width: 100%;
    position: relative;
    padding-top: 100%;
  }
}

.img-responsive {
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.font-mid {
  font-weight: normal;
}

.font-light {
  font-weight: 100 !important;

  @media (max-width: 425px) {
    font-size: 24px;
  }
}

.swiper {
  &-container {
    overflow: visible;
  }

  &-slide {
    opacity: 0.1;
    transition: 0.2s opacity;
    h3,
    h6,
    button {
      opacity: 0;
      transition: transition((opacity, transform));
    }
    h3 {
      transform: translate(0, 10px);
    }
    h6 {
      transform: translate(0, 20px);
    }
    &-active {
      opacity: 1;
      .people-card_overlay:hover {
        button {
          opacity: 1;
        }
      }
      h3,
      h6 {
        opacity: 1;
        transform: translate(0, 0%);
      }
    }
  }
  &-pagination {
    position: absolute;
    top: 0;
    right: 0;
    color: rgba($white, 0.2);
    &-current {
      color: rgba($white, 1);
      letter-spacing: 20px;
    }
  }
  &-button {
    &-next {
      position: absolute;
      bottom: 0;
      padding: 2rem;
      right: 0;
      background: transparent;
      font-family: $font;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 13px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      border: 0;
      cursor: pointer;
      z-index: 10;
      color: #d5222c;
      &:hover {
        filter: brightness(1.2);
        opacity: 1;
      }
    }
    &-prev {
      position: absolute;
      bottom: 0;
      right: 100px;
      background: transparent;
      font-family: $font;
      font-style: normal;
      padding: 2rem;
      font-weight: bold;
      font-size: 14px;
      line-height: 13px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      color: rgba($white, 0.5);
      border: 0;
      cursor: pointer;
      z-index: 10;
      &:hover {
        filter: brightness(1.2);
        transition: transition((filter, color));
        color: rgba($white, 1);
      }
    }
  }
}

.person-bio {
  .name {
    font-weight: normal;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.01em;
  }
  .rank {
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 2rem;
  }
  .bio {
    font-size: 16px;
    line-height: 27px;
    color: #ffffff;
  }
  .close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    padding: 2rem 0rem;
    border: 0;
    color: $primary;
    font-size: 14px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    img {
      margin-right: 2rem;
    }
  }
}

.next {
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  margin: 5rem auto;
  position: relative;
  margin-bottom: 1rem;
  &:before {
    content: attr(data-bg);
    font-family: Gotham Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 7rem;
    /* identical to box height */
    z-index: 0;
    pointer-events: none;
    width: max-content;
    text-align: center;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 2px rgba(white, 0.2);
    mix-blend-mode: normal;
    opacity: 0.7;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transition((transform, color));

    @include break-md {
      font-size: 6rem;
    }
  }
  &:hover {
    &:before {
      color: rgba($white, 0.05);
      transform: translate(-50, -50%);
    }
  }
  h3 {
    font-family: Gotham Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    transition: transition(transform);

    color: #d5222c;
  }
  a {
    font-family: Gotham Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 120%;
    /* identical to box height, or 36px */
    position: relative;
    transition: transition(transform);

    &:before {
      content: "";
      position: absolute;
      top: calc(100% + 1rem);
      left: 0;
      transition: transition((transform));
      width: 100%;
      transform: scaleX(1);

      background: rgba($primary, 1);
      height: 2px;
    }
    &:hover {
      transform: scale(1.1);
      &:before {
        transform: scaleX(0);
      }
      h3 {
        transform: translate(-10px, 0);
      }
    }
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: #ffffff;
  }
}

.big-section {
  padding: 2rem 0;
  margin: 5rem auto;
  &_services {
    @include break-lg {
      margin-top: 10rem;
    }
  }
}

.select-none {
  user-select: none;
  &-children {
    * {
      user-select: none;
    }
  }
}

.mobile-max-content {
  @include break-lg {
    height: max-content;
  }
}

.d-lg-none {
  @include break-lg {
    display: none;
  }
}

.d-flex {
  display: flex;
  &.align-center {
    align-items: center;
  }
}

.child-block {
  * {
    display: inline-block;
  }
}
.location-section {
  h2 {
    margin-top: 200px;
  }

  p {
    width: 75%;
    margin: 0 auto;
  }
}

.person-bio {
  h1 {
    margin-bottom: 60px;
  }
}

.person {
  h6 {
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 3px;
  }

  h3 {
    margin-bottom: 10px;
  }
}

.about-awards p {
  font-size: 21px;
}

.swiper-button-next,
.swiper-button-prev {
  font-size: 11px;

  svg {
    width: 9px;
  }
}

.two-col {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .form-group {
    width: 48%;

    @media (max-width: 425px) {
      width: 100%;
    }
  }
}

.contact-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.make-text h1 {
  width: 85%;
  font-weight: 300;
  margin-top: 30px;
  line-height: 1.3;
  margin-bottom: 60px;
}

@include break-md {
  .portfolio-text {
    margin-bottom: 6rem;

    h1 {
      font-size: 24px;
    }
  }
}

.scrollIndicator {
  display: none !important;
  position: fixed;
  right: 0;
  padding: 2rem;
  height: 100%;
  @include break-lg {
    display: none;
  }
  span {
    position: absolute;
    top: 2rem;
    max-height: calc(100% - 4rem);
    left: 50%;
    transform: translate(0, 0);
    width: 1px;
    background: rgba($primary, 1);
  }
  p {
    color: rgba($primary, 1);
    position: absolute;
    top: 50%;
    letter-spacing: 2px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    font-family: Gotham Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.3em;
    line-height: 150%;
    text-transform: uppercase;
  }
}

.viewOnHover {
  display: flex;
  align-items: center;
  transform: scale(0.5);
  transform-origin: bottom right;
  @include break-md {
    display: none;
  }
  p {
    margin-right: 2rem;
    color: rgba($primary, 1);
    letter-spacing: 2px;
    font-family: Gotham Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.3em;
    line-height: 150%;
    text-transform: uppercase;
  }
}

.forohfor {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  a {
    font-weight: 900;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: transition(color);
    display: block;
    position: relative;
    text-align: center;
    line-height: 140%;
    &:before {
      content: "";
      position: absolute;
      display: block;
      bottom: -2rem;
      width: 100%;
      height: 5px;
      left: 0%;
      background: white;
      transition: transition(background), transition(transform);
      transform: scaleX(0);
    }
    &:hover {
      color: $primary;
      &:before {
        background: $primary;
        transform: scaleX(1);
      }
    }
  }
  h1 {
    font-weight: 100;
    margin-bottom: 2rem;
  }
  height: 100vh;
  &:before {
    top: 50% !important;
  }
}

.quick-nav {
  display: flex;
  padding: 5vw;
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
    width: 100%;
    justify-content: center;
    @include break-lg {
      flex-flow: column;
    }
    li {
      display: flex;
      a {
        letter-spacing: 0.03em;
        font-size: 14px;
        padding: 0;
        margin: 0 1rem;
        text-transform: uppercase;
        color: rgba(white, 0.5);
        transition: color 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          line-height: 200%;
        }
        &:hover {
          color: $primary;
        }
        &:after {
          @include break-lg {
            content: none;
            display: none;
          }
          font-size: 8px;
          content: "";
          margin-top: -3px;
          width: 5px;
          background-size: cover;
          height: 5px;
          background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='5' fill='%23D5222C'/%3E%3C/svg%3E%0A");
          margin-left: 2rem;
          display: block;
          color: $primary;
        }
        @include break-lg {
          padding: 2rem 0;
        }
      }
      &:last-child {
        a:after {
          content: none;
        }
      }
    }
  }
}

.scrollmouse {
  position: fixed;
  bottom: 2rem;
  right: 5%;
  transition: 0.5s opacity;
  svg {
    width: 35px;
    height: 35px;
  }
}
