.navbar {
  padding: 1rem 0;
  position: fixed;
  z-index: 1;

  @include break-lg {
    .logo_text {
      display: none;
    }
  }
  .burger-menu {
    padding: 1rem 2rem;
    @media (max-width: 425px) {
      padding: 0.5rem 0;
    }
  }
  &-exit {
    position: absolute;
    z-index: 100;
    background: transparent;
    font-size: 3rem;
    border: 0;
    color: white;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    right: 5%;
    top: 50px;
    img {
      width: 20px;
      height: 20px;
      margin-left: 1rem;
    }
  }
  &-innerNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 5%;
    left: 50%;
    img {
      max-height: 65px;
    }
  }
  &-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // background: $dark;
    z-index: -1;
  }
  &_overlay {
    width: 100%;
    height: 100vh;
    z-index: 999;
    left: 0;
    .logoItems {
      position: fixed;
      bottom: 0;
      left: 0;
      transform: translate(0, 0);
      padding: 2rem;
      display: flex;
      align-items: center;
      img {
        &:first-child {
          max-width: 50px;
          margin-right: 1rem;
        }
        &:last-child {
          max-height: 20px;
        }
      }
    }
  }
  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 5%;
    left: 50%;
    z-index: 999;
    transition: opacity 1s;
    transform: translate(-50%, 0);
    .logo {
      max-width: 65px;
      &_text {
        max-height: 22px;
      }
    }
  }
  &-brand {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    img {
      margin: 0 1rem;
    }
  }
  &-toggle {
    margin-left: auto;
    button {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 11px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      color: #ffffff;
      background: none;
      display: flex;
      align-items: center;
      border: 0;
      img {
        margin-left: 1rem;
      }
    }
  }
  &-nav {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 100%;
    transform: translate(-50%, -50%);
    list-style: none;
    &_link {
      position: absolute;

      width: 200px;
      height: 100px;
      top: 0;
      left: 0;
      a {
        width: 100%;
        transform: translate(-50%, -50%);
        height: 100%;
      }
      display: flex;
      .video-particles {
        width: 100%;
        &-wrapper {
          margin-left: auto;
        }
      }
      .name {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0%;
        transform: translate(0%, -50%);
        pointer-events: none;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        text-decoration: none;
        color: #ffffff;
        .description {
          display: block;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          text-transform: none;

          line-height: 30px;
          letter-spacing: -0.02em;
          position: absolute;
          width: max-content;
          max-width: 90vw;
        }
      }
      .num {
        font-weight: bold;
        font-size: 70px;
        line-height: 67px;
        /* identical to box height */
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.05);
        mix-blend-mode: overlay;

        position: absolute;
        top: 45%;
        left: -12%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &_spacer {
    height: 100px;
  }
}

.nav-overarch {
  position: fixed;
  z-index: 999;
  transition: transition(opacity);
  .burger-menu {
    transition: transition(background);
  }
  .logo_text {
    transition: transition(opacity);
  }
  &.scrolled {
    opacity: 0;
    .burger-menu {
      // background: $dark;
    }
    .logo_text {
      opacity: 0;
    }
    // .navbar_top {
    //   background: rgba($dark, 0.2);
    //   backdrop-filter: blur(20px);
    // }
  }
}
