$dark: #02000c;
$primary: #d5222c;
$white: white;
// $teal: #4efdfe;
$teal: $primary;
$colors: (
  "dark": $dark,
  "primary": $primary,
  "white": $white,
  "teal": $teal
);
$breakpoints: (
  "xl": 1340px,
  "lg": 1100px,
  "md": 567px
);
$font: "Gotham Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$text-align: (
  "center": center,
  "left": left,
  "justify": justify,
  "right": right
);
$gutter: 12px;

@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color;
  }
  .text-#{$name} {
    color: $color;
  }
}
@each $name, $align in $text-align {
  .text-#{$name} {
    text-align: $align;
  }
}

@function transition($transitions) {
  $default: null;
  @each $transition in $transitions {
    $default: $transition 1s cubic-bezier(1, 0, 0, 1), $default;
  }
  @return $default;
}
