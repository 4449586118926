h1 {
  margin: 0;
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  /* or 36px */

  letter-spacing: -0.01em;
}

h2 {
  margin: 0;
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 120%;
  /* or 36px */

  letter-spacing: -0.01em;
}

h4 {
  margin: 0;
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 39px;
  /* or 150% */
  letter-spacing: -0.01em;
  font-weight: 100;
  //   b {
  //     font-weight: 400;
  //   }
}

h6 {
  margin: 0;
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.3em;
  line-height: 150%;
  text-transform: uppercase;
}

p {
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: 100;
  font-size: 13px;
  /*line-height: 27px;*/
}

.full-height.centered.intro.mex-height {
  h4 {
    font-size: 20px;
    text-align: center;
    line-height: 1.6;
    width: 59%;
    margin: 0 auto;
    padding-bottom: 40px;

    b {
      font-weight: 300;
    }
  }
}
