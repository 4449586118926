.row {
  display: flex;
  flex-wrap: wrap;

  &.nowrap {
    flex-wrap: nowrap;
  }
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.container-big {
  width: 90%;
  margin: 0 auto;
}

@mixin break-xl {
  @media screen and (max-width: map-get($map: $breakpoints, $key: "xl")) {
    @content;
  }
}
@mixin break-lg {
  @media screen and (max-width: map-get($map: $breakpoints, $key: "lg")) {
    @content;
  }
}
@mixin break-md {
  @media screen and (max-width: map-get($map: $breakpoints, $key: "md")) {
    @content;
  }
}
@mixin break-sm {
  @media screen and (max-width: map-get($map: $breakpoints, $key: "sm")) {
    @content;
  }
}

@each $name, $breakpoint in $breakpoints {
  .d-#{$name}-none {
    @media screen and (max-width: $breakpoint) {
      display: none;
    }
  }
  @for $i from 1 through 12 {
    $single: (100 / 12);
    $base: ($single * $i);
    $num: $i;
    .col-#{$name}-#{$num} {
      flex: 0 0 #{$base + "%"};
      max-width: #{$base + "%"};
      padding-left: $gutter;
      padding-right: $gutter;
    }
    .offset-#{$name}-#{$num} {
      margin-left: #{$base + "%"};
    }
  }
  @media screen and (max-width: $breakpoint) {
    @for $num from 1 through 12 {
      .offset-#{$name}-#{$num} {
        margin-left: 0;
      }
    }
    @for $i from 1 through 12 {
      .col-#{$name}-#{$i} {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: $gutter;
        padding-right: $gutter;
        margin-top: $gutter;
        margin-bottom: $gutter;
      }
    }
  }
}

.full-height {
  position: relative;

  display: flex;
  height: calc(100vh - 100px);
  &.intro {
    color: rgba($white, 0.5);
    max-height: 100vh;
    overflow: hidden;
    height: 100vh;
    b {
      color: rgba($white, 1);
    }
    @include break-lg {
      h4 {
        font-size: 16px;
        line-height: 24px;
      }
      .btn-loading {
        padding: 1rem 0rem;
        letter-spacing: 2px;
      }
    }
  }
  &.centered {
    align-items: center;
    justify-content: center;
  }
}

@for $i from 1 through 10 {
  .mw-#{($i * 100) + "px"} {
    max-width: #{$i * 100}px;
  }
}

.m-auto {
  margin: 0 auto;
}

@for $i from 0 through 5 {
  .p-#{$i} {
    padding: $gutter * $i;

    @include break-md {
      padding: ($gutter * $i) / 3;
    }
  }
  .p-t-#{$i} {
    padding-top: $gutter * $i;
  }
  .p-l-#{$i} {
    padding-left: $gutter * $i;
  }
  .p-b-#{$i} {
    padding-bottom: $gutter * $i;
  }
  .p-r-#{$i} {
    padding-right: $gutter * $i;
  }
  .p-y-#{$i} {
    padding: ($gutter * $i) 0;
  }
  .p-x-#{$i} {
    padding: 0 ($gutter * $i);
  }
  .m-#{$i} {
    margin: $gutter * $i;
  }
  .m-t-#{$i} {
    margin-top: $gutter * $i;
  }
  .m-l-#{$i} {
    margin-left: $gutter * $i;
  }
  .m-b-#{$i} {
    margin-bottom: $gutter * $i;
  }
  .m-r-#{$i} {
    margin-right: $gutter * $i;
  }
  .m-y-#{$i} {
    margin: ($gutter * $i) auto;
    @include break-md {
      margin: ($gutter * $i) / 2 auto;
    }
  }
  .m-x-#{$i} {
    margin: 0 ($gutter * $i);
  }
}

.d-flex {
  display: flex;
  &.column {
    flex-flow: column;
  }
}

.h-100vh {
  height: 100vh;
}

.to-front {
  z-index: 10;
}

.container {
  width: 90%;
  max-width: map-get($map: $breakpoints, $key: "xl");
  margin: 0 auto;
  @include break-xl {
    max-width: map-get($map: $breakpoints, $key: "lg");
  }
  @include break-lg {
    max-width: map-get($map: $breakpoints, $key: "md");
  }
}

.m-t-5-md {
  @include break-lg() {
    margin-top: 128px;
  }
}
