.video-particles {
  cursor: pointer;
  position: relative;
  video {
    transition: transition(transform);
  }
  &-wrapper {
    position: relative;
  }
  &:hover {
    .video-particles_inner {
      transform: translate(-50%, -50%) scale(1.5);
    }
    video {
      transform: scale(1.2);
    }
  }
  &_inner {
    position: absolute;
    width: 40%;
    height: 40%;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    border: 1px solid $teal;
    transform: translate(-50%, -50%);
    transition: transition(transform);
  }
}

.particle_link {
  position: relative;
  width: 300px;
  margin: 0 auto;
  transition: transition((margin-top));
  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
  svg {
    transition: transition(transform);
  }
  .video-particles {
    width: 100%;
    &-wrapper {
      margin-left: right;
    }
  }
  .next {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(0%, -50%);
    transform-origin: center center;
  }
  .name {
    position: absolute;
    transition: transition(transform);
    z-index: 1;
    top: 50%;
    &.offset {
      top: 70%;
      right: 50%;
    }
    right: 0;
    transform: translate(0%, -50%);
    &.offset {
      top: 70%;
      left: 50%;
    }
    transform-origin: center center;
    pointer-events: none;
    width: 50%;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    text-decoration: none;
    color: #ffffff;
    .description {
      display: block;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      text-transform: none;

      line-height: 19px;
      letter-spacing: -0.02em;
      position: absolute;
      width: max-content;
      max-width: 90vw;
    }
  }
  .num {
    font-weight: bold;
    font-size: 70px;
    line-height: 67px;
    /* identical to box height */
    text-transform: uppercase;
    color: #ffffff;
    mix-blend-mode: overlay;
    opacity: 0.2;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
