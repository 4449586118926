.card {
  &-holder {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: 0 -2rem;
    @include break-lg {
      flex-flow: column;
    }
  }
  &_front,
  &_back {
    padding: 3rem 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    // justify-content: space-between;
    backface-visibility: hidden;
    img {
      margin-bottom: 20px;
    }
  }
  &_front {
    p {
      margin-top: 20px;
    }
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-template-rows: repeat(2, minmax(100px, 1fr));
    width: 100%;
    .flex-cont {
      flex: 1 0 50%;
      display: flex;
      margin: 1rem 0;
      &:first-child {
        align-items: flex-end;
      }
      &:last-child {
        align-items: flex-start;
      }
    }
    img {
      padding-bottom: 0;
    }
    p {
      text-align: center;
      align-self: flex-start;
      margin: 0 auto;
      margin-top: 1rem;
      max-width: 100% !important;
    }
  }
  &_back {
    transform: rotateY(180deg);
    width: 100%;
    position: relative;
    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      // background: rgba($dark, 1);
      clip-path: polygon(
        75% 100%,
        100% 25%,
        75% 0,
        25% 0,
        0 25%,
        0 75%,
        25% 100%,
        0 100%,
        0 0,
        75% 0,
        75% 0,
        100% 0,
        100% 25%,
        100% 25%,
        75% 100%,
        100% 75%,
        100% 100%
      );
      border: 1px solid $primary;
    }
  }
  &-single {
    // max-width: 320px;
    flex: 1 1 20%;

    perspective: 1000px;
    @include break-xl {
      flex: 1 0 30%;
    }

    margin: 2rem;
    &_inner {
      display: flex;
      height: 100%;
      width: 100%;
      min-height: 400px;
      align-items: stretch;
      transform-style: preserve-3d;
      transform-origin: center center;
      z-index: 1;
      // background: $dark;
      // transition: transition((box-shadow, transform, background));
      position: relative;
    }
    img {
      max-width: 50px;
      height: auto;
    }
    h3 {
      line-height: 36px;
    }
    // &:before {
    //   position: absolute;
    //   border: 1px solid $primary;
    //   width: 100%;
    //   height: 100%;
    //   content: "";
    //   z-index: -10;
    //   left: 0;
    //   top: 0;
    // }
    // &:hover {
    //   transform: translate(0, -5px) scale(1.01);
    //   box-shadow: 0 30px 50px -20px rgba($teal, 0.2);
    //   background: $dark;
    //   &:before {
    //     z-index: 0;
    //   }
    // }
    &.flexed {
      flex-flow: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 4rem;
      a {
        font-size: 12px;
        line-height: 11px;
        letter-spacing: 0.5em;
        text-transform: uppercase;
        color: $teal;
        position: relative;
        &:hover {
          &:before {
            transform: scaleX(1);
          }
        }
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          bottom: -1rem;
          height: 1px;
          background: $teal;
          transform: scaleX(0.2);
          left: 0;
          transform-origin: center left;
          transition: transition(transform);
        }
      }
      p {
        max-width: 100%;
        margin-bottom: 0;
      }
    }
    p {
      max-width: 100%;
    }
  }
}

.people-card {
  margin: 2rem auto;
  padding: 3rem;
  button {
    font-family: Gotham Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($dark, 0.8);
    color: white;
    border: 0;
    opacity: 0;
    &:disabled {
      opacity: 0 !important;
      pointer-events: none;
    }
  }
  &_overlay {
    position: relative;
  }
  &_inner {
    width: 100%;
    margin: 0 auto;
    position: relative;
    .person {
      width: 100%;
    }
    img {
      width: 100%;
      margin: 0;
      height: 300px;
      object-fit: cover;
    }
  }
}
