form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  textarea,
  input,
  select,
  button {
    font-family: $font;
    outline: 0;
  }
  label {
    margin-bottom: 1rem;
  }
  input,
  textarea {
    background: rgba($white, 0.1);
    padding: 1rem;
    border: 0;
    color: $white;
    resize: none;
  }
  select {
    -webkit-appearance: none;
    background: url("../images/arrow-down.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem) 50%;
    background-color: rgba($white, 0.1);
    padding: 1rem;
    border: 0;
    color: $white;
    position: relative;
    option {
      color: black;
    }
  }
  button {
    outline: 0;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    color: #ffffff;
    background: $primary;
    border-radius: 3px;
    border: 0;
    padding: 1rem;
    box-shadow: 0 0px 40px -10px rgba($primary, 0);
    transition: transition((filter, box-shadow));

    &:hover {
      filter: brightness(1.1);
      box-shadow: 0 20px 50px -10px rgba($primary, 0.7);
    }
    &:active {
      filter: brightness(0.8);
      transition: 0s;
      box-shadow: 0 0px 40px -10px rgba($primary, 0);
    }
  }
}

.alert {
  padding: 1rem;
  border-radius: 3px;

  &-success {
    background: #00ffc72b;
    border: 1px solid #00ffc7;
  }
  &-danger {
    background: rgba($primary, 0.1);
    border: 1px solid $primary;
  }
}
