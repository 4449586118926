@keyframes borderAnimation {
  0% {
    clip-path: polygon(0 0, 50% 0, 50% 50%, 0 50%);
  }
  10% {
    clip-path: polygon(0 0, 10% 0, 10% 100%, 0 100%);
  }
  25% {
    clip-path: polygon(0 50%, 50% 50%, 50% 100%, 0 100%);
  }
  50% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%);
  }
  60% {
    clip-path: polygon(90% 0, 100% 0, 100% 100%, 90% 100%);
  }
  75% {
    clip-path: polygon(50% 0, 100% 0, 100% 50%, 50% 50%);
  }
  100% {
    clip-path: polygon(0 0, 50% 0, 50% 50%, 0 50%);
  }
}
