.canvas-background {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
}

.mouse {
  background: transparent;
  mix-blend-mode: difference;
  pointer-events: none;
  width: 50px;
  height: 50px;
  position: fixed;
  top: -25px;
  left: -25px;
  z-index: 1000;
  border: 1px solid $teal;
  border-radius: 100px;
}
