@import "./fonts/gotham";
@import "./keyframes";
@import "./vars";
@import "./grid";
@import "./base";
@import "./typo";
@import "./headers";
@import "./particles";
@import "./navbar";
@import "./cards";
@import "./canvas";
@import "./interactionParticles";
@import "./form";
@import "./blob";

a {
  cursor: pointer;
}

.m-b-5 {
  margin-bottom: 10px;
}

.col-margin-left {
  margin-left: 30px;
}

.hidethisinstantly {
  display: none !important;
}

.about-intro {
  display: flex;
  min-height: 100vh;
  align-items: center;
}

.location-image-container {
  text-align: center;

  img {
    width: 100%;
    height: auto;
  }
}

.fancy-subtitle {
  width: 100%;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 0 10px 0 !important;

  font-family: Gotham Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 30px !important;
  line-height: 120% !important;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  //transition: transition(transform);

  color: #d5222c;
}

div.about-intro.history-block > h3 {
}

.consistent-p {
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: 100;
  font-size: 13px;
}

/* ari generated styles */

.rnd-page .card-single,
.services-page .card-single {
  flex: 1 1 20%;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  margin: 2rem;
}

.rnd-page .card-single_inner,
.services-page .card-single_inner {
  display: flex;
  height: fit-content;
  width: 100%;
  min-height: fit-content;
  align-items: stretch;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  z-index: 1;
  position: relative;
}

.rnd-page .card-single {
  flex: 1 1 20%;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  margin: 2rem;
}

.rnd-page .card-single_inner {
  display: flex;
  height: fit-content;
  width: 100%;
  min-height: fit-content;
  align-items: stretch;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  z-index: 1;
  position: relative;
}

.rnd-page
  div.card-single:nth-child(1)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/casino_card.gif");
}

.rnd-page
  div.card-single:nth-child(2)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/retailer.gif");
}

.rnd-page
  div.card-single:nth-child(3)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/clothing.gif");
}

.rnd-page
  div.card-single:nth-child(4)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/manufacturer.gif");
}

.rnd-page
  div.card-single:nth-child(5)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/auto.gif");
}

.rnd-page
  div.card-single:nth-child(6)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/hospitality.gif");
}

.rnd-page
  div.card-single:nth-child(7)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/telco.gif");
}

.rnd-page
  div.card-single:nth-child(8)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/consumerelectronics.gif");
}

.rnd-page
  div.card-single:nth-child(9)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/blueprint.gif");
}

.rnd-page
  div.card-single:nth-child(10)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/imagechange.gif");
}

.rnd-page
  div.card-single:nth-child(11)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/hedgefund.gif");
}

.rnd-page
  div.card-single:nth-child(12)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/cybersecurity.gif");
}

.services-page
  div.card-single:nth-child(1)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/neural_network.gif");
}

.services-page
  div.card-single:nth-child(2)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/education.gif");
}

.services-page
  div.card-single:nth-child(3)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/partnership.gif");
}

.services-page
  div.card-single:nth-child(4)
  > div.card-single_inner
  > div.card_front
  > div
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/consulting.gif");
}

.rnd-page .apparellist {
  /* font-size: 11px; */
  font-weight: lighter;
}

.rnd-page.left-bar,
.services-page.left-bar {
  left: 3.5vw;
  height: 90px;
  width: 800px;
  overflow: hidden;
  overflow-wrap: break-word;
  font-size: 0.7rem;
  white-space: initial;
  display: block;
  line-height: 20px;
  top: 50px;
  /* border-top: 2px solid white; */
}

.left-bar > a {
  opacity: 0.8;
  font-weight: 500;
}

.left-bar > a + a {
  margin-left: 30px;
}

.contact-container > .container > .row > div.col-lg-4 {
  margin: 0 auto;
}

.contact-container > .container > .row > div.col-lg-6 {
  display: none;
}

.swiper-button-next,
.swiper-button-prev {
  display: none;
}

div[data-overlay="ABOUT"]::before,
div[data-overlay="history"]::before,
div[data-overlay="VALUE"]::before,
div[data-overlay="TEAM"]::before,
div[data-overlay="geo"]::before,
div[data-bg="Services"]::before,
div[data-bg="Portfolio"]::before,
div[data-bg="Case Studies"]::before,
div[data-bg="Contact"]::before {
  font-size: 6rem;
  opacity: 0.5;
}

.fancy-subtitle,
.bio > ul > li,
.people-card_inner > .person > :is(h6, h3),
.person-bio {
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: normal;
}

.fancy-subtitle {
  padding-top: 6px;
  font-size: 30px;
  line-height: 120%;
  position: relative;
  transition: transform 1s cubic-bezier(1, 0, 0, 1);
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #ffffff;
}

h3.rank {
  display: none;
}

.person-bio > h1.name {
  visibility: hidden;
}

.consistent-p,
.bio > ul > li,
.person-bio {
  line-height: 200%;
  font-size: 16px;
}

div.swiper-wrapper {
  left: -800px;
}

.swiper-slide :is(h3, h6) {
  opacity: 1;
  line-height: 10px;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 485px;
}

.swiper-button-prev {
  left: -380px;
}

.swiper-button-next {
  right: 380px;
}

.about-intro {
  min-height: 90vh;
}

div.container-big.p-t-5.p-b-5.big-section {
  display: none;
}

.casinomlvid,
.retailermlvid,
.apparelimg1,
.fireimg1,
.autoimg1,
.drugabusemlvid,
.audiomlvid,
.emailmlvid,
.emailmlvid,
.blueprintsegmentationmlvid,
.imagechangemlvid,
.cryptomlvid {
  visibility: visible;
}

.casinomlvidmobile,
.retailermlvidmobile,
.apparelimg1mobile,
.fireimg1mobile,
.autoimg1mobile,
.drugabusemlvidmobile,
.audiomlvidmobile,
.emailmlvidmobile,
.emailmlvidmobile,
.blueprintsegmentationmlvidmobile,
.imagechangemlvidmobile,
.cryptomlvidmobile {
  display: none;
}

.viewport > .left-bar {
  display: block;
}

.rnd-page > .row > .col-lg-10 :is(video, img) {
  height: 200px;
  float: right;
  margin: 20px;
}

.rnd-page > .row > .col-lg-10 li {
  opacity: 1;
  font-size: 14px;
  line-height: 18px;
}

.rnd-page > .row > .col-lg-10 :is(div, p) {
  opacity: 1;
  font-size: 15px;
  line-height: 21px;
}

.rnd-page .card-single_inner {
  height: 250px;
}

div.container.m-b-5.p-t-5.p-b-5.big-section > div > div > div:nth-child(2) {
  display: none;
}

.card-single > .card-single_inner > .card_front > .flex-cont > img {
  max-width: 200px;
}

.card-single:nth-child(1)
  > .card-single_inner
  > .card_front
  > .flex-cont
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/p_masterson.png");
}

.card-single:nth-child(2)
  > .card-single_inner
  > .card_front
  > .flex-cont
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/a_smith.png");
}

.card-single:nth-child(3)
  > .card-single_inner
  > .card_front
  > .flex-cont
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/a_elson.png");
}

.card-single:nth-child(4)
  > .card-single_inner
  > .card_front
  > .flex-cont
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/m_spanos.png");
}

.card-single:nth-child(5)
  > .card-single_inner
  > .card_front
  > .flex-cont
  > img {
  content: url("https://api.hypereonlabs.com/wp-content/uploads/2022/08/j_singh.png");
}

.about-awards > .card-single {
  margin: unset;
  flex: 1 1 10%;
}

div.container-big.p-t-5.p-b-5.big-section {
  display: block;
}

@media only screen and (max-width: 1105px) {
  .casinomlvid,
  .retailermlvid,
  .apparelimg1,
  .fireimg1,
  .autoimg1,
  .drugabusemlvid,
  .audiomlvid,
  .emailmlvid,
  .blueprintsegmentationmlvid,
  .imagechangemlvid,
  .cryptomlvid {
    display: none;
  }

  .casinomlvidmobile,
  .retailermlvidmobile,
  .apparelimg1mobile,
  .fireimg1mobile,
  .autoimg1mobile,
  .drugabusemlvidmobile,
  .audiomlvidmobile,
  .emailmlvidmobile,
  .blueprintsegmentationmlvidmobile,
  .imagechangemlvidmobile,
  .cryptomlvidmobile {
    display: block;
  }

  .viewport > .left-bar {
    visibility: hidden;
  }

  .rnd-page :is(video, image) {
    height: unset;
    width: 100%;
    max-width: 400px;
    float: unset;
    margin: 20px;
    text-align: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 500px;
  }

  .swiper-button-prev {
    left: 0px;
  }

  .swiper-button-next {
    right: 20px;
  }

  div.swiper-wrapper {
    left: 0px;
  }

  div[data-overlay="ABOUT"]::before,
  div[data-overlay="history"]::before,
  div[data-overlay="VALUE"]::before,
  div[data-overlay="TEAM"]::before,
  div[data-overlay="geo"]::before,
  div[data-bg="Services"]::before,
  div[data-bg="Portfolio"]::before,
  div[data-bg="Case Studies"]::before,
  div[data-bg="Contact"]::before {
    font-size: 4rem;
    opacity: 0.5;
  }

  div.about-intro.about-block > div > div > div > h1 {
    padding-top: 4px;
  }
  div.about-intro.history-block > div > div > div > h3 {
    padding-top: 14px;
  }
  div.about-intro.value-block > div > div > div > h3 {
    padding-top: 20px;
  }
  div.about-intro.team-block > div > div > div > h6 {
    padding-top: 5px;
  }

  div[data-bg="Services"] > a {
    padding-bottom: 13px;
  }

  div.container.m-b-5.p-t-5.p-b-5.big-section > div > div > div:nth-child(2) {
    display: block;
  }

  div.container-big.p-t-5.p-b-5.big-section {
    display: none;
  }

  .team-block .fancy-subtitle {
    top: 22px;
  }
}

.about-awards .card_back,
.about-awards .card_front {
  padding: 0;
}

.about-awards .card_back {
  top: -70px;
}

.about-awards .card-single_inner {
  top: -150px;
}

/* ari generated styles */

.no-v-space {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.about-awards .card_back ul li {
  font-size: 12px;
}
