header {
  &.intro {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 15vh;

    img {
      &:first-child {
        width: 70px;
      }

      &:last-child {
        width: 130px;
      }
    }
  }
}
